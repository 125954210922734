
/* HEADER */
:root {
  --header-background-color: white;
  --main-text-color: black;
  
}

/* body{
  padding: 0;
  margin:0;
} */

.header {
  
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -1%;
  background-color: var(--header-background-color);
}

.header-container {
  
  display: flex;
  
  align-items: center;
  justify-content: space-around;
  width: 100%;
  
  padding: 10px;


  
}


.header-logo-container {
  height: auto;
  width: auto;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  
}

.header-logo {
  height: 30px;
}

.header-sections {

  margin: 0px;
  width: auto;
 
  font-weight: bold;
  height: auto;
  
 
}

@media (max-width: 1024px) {

  .header-container {
    display: flex;
    
    align-items: center;
    justify-content: space-around;
    width: 100%;
    
    padding: 10px;
    
  
    /* margin-bottom: 30px; */
    
  }
  .header-logo-container {
   
    height: auto;
    width: auto;
    
    display: flex;
    /* align-items: center;
    justify-content: center; */
 
    margin-top: 3px;
    height: 30px;
    
  }
  
  .header-logo {
    height: 100%;
    width: 100%;
  }

}

/* .support-selection,
.locate-selection {
  border: none;
  font-weight: bold;
  
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 15.7px;
}
.page-language{
  color: rgb(0, 0, 0);
  
} */

/* MAIN BODY CONTENT */
.main {
  position: relative;
}

.main-display-content {
  position: relative;
  background-color: white;
  top: auto;
  width: 100%; 
  height: 56vh; 
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.main-display-img-container {
  position: absolute;

  left: 0; 
  bottom: -15px;
  width: 100%;
  
  
  height: 80%;
  
 
}

.main-display-content img {
  width: 100%; 
  height: 100%; 
  object-fit: cover;
 
}


/* DESKTOP DESIGN */

.main-display-info {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr;
  left: 15%;  
  top: -4%;   
  width: 80%; 
  padding: 2%; 
  box-sizing: border-box; 
  max-width: 1200px; 

}

.welcome-sentence {
  background-color: rgba(46, 46, 46, 1);
  padding: 8px;
  border-radius: 15px;
  font-size: 12px;
  max-width: 34.5vh;
  min-width: none;
  font-weight: 700;
}

.help-sentence {
  
  font-weight: 700;
  font-size: 50px;
  min-width: none;
  max-width:  190vh;
  margin-top: 15px;
  margin-bottom: 0px;
  color: white;
  
 
  border-radius: 15px;
}


/* MOBILE DESIGN */
@media (max-width: 1024px) {
  .main-display-content {
    position: relative;
    background-color: white;
    top: auto;
    width: 100%; 
    height: 60vh; 
    display: flex;
    justify-content: center; 
    align-items: center; 
  }

  .main-display-img-container {
    position: absolute; 
    
    left: 0; 
    bottom: -15px;
    width: 100%;
    height: 80%;
    
  
   
  }
  
  .main-display-content img {
    object-fit: cover;
    width: 100%; 
    height: 100%; 
  }
 
  .main-display-info {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr;
    left: 5%;
    top: 10%;
    width: 80%;
    padding: 2%;
    box-sizing: border-box;
    max-width: 1200px;

  }

  .welcome-sentence {
    background-color: rgba(46, 46, 46, 1);
    padding: 8px;
    border-radius: 15px;
    font-size: 12px;
    
    max-width: 54vh;
    min-width: none;
  }

  .help-sentence {
    font-weight: normal;
    font-size: 30px;
    min-width: none;
    max-width: 100vh;
    margin-top: 15px;
    margin-bottom: 0px;
    color: white;
    
    border-radius: 15px;
  }

  
  
  
}

@media (max-height: 541px) {
  .header-container {
    padding-bottom: 45px;
    height: 100%;
    margin-top: 15px;
  }


  .main-display-content {
    position: relative;
   
    top: auto;
    width: 100%; 
    height: 150vh; 
    display: flex;
    justify-content: center; 
    align-items: center; 
  }

  .main-display-img-container {
    padding: 5px;
   
  }


}





.describtion-info {
  font-weight:lighter;
  
  background-color: rgba(46, 46, 46, 1);
  padding: 8px;
  border-radius: 15px;
  max-width: 67vh;
}

/* Turned off if needed you can turn it on */
.search-container {
  display: none;
  align-items: center;
  width: 138%;
  max-width: 700px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  overflow: hidden;
  background-color: white; 
  height: 75px;
  
}


.search-input {
  flex: 1; 
  border: none; 
  padding: 10px; 
  font-size: 16px; 
  margin-left: 20px;
}

.search-input:focus {
  outline: none;
}

.search-button {
  background-color: #333; 
  color: white; 
  border: none; 
  padding: 20px 40px; 
  font-size: 16px;
  margin-right: 8px;
  border-radius: 5px;
  transition: 0.2s;
}

.search-button:active {
  background-color: black;
 
}



/*---------------------------GAME CARD GRID CSS*/
/* SECOND MAIN BODY */
.second-main-content {
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%; 
  padding: 10px 0px 15px 0px;
  height: auto;
  width: auto;
}


.game-selection-content {
  width: 64%;
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
  }
  

.pc-and-console {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px rgba(7, 7, 7, 0.4),
  0px 22.3363px 17.869px rgba(182, 195, 193, 0.0596107),
  0px 6.6501px 5.32008px rgba(182, 195, 193, 0.0403893);
  border-radius: 8px;
}

.load-more-btn {
  position: relative;
  left: 45%;
  margin-bottom: 10px;
  border-radius: 5px;
  
  background-color: rgb(0,0,0,0.9);
  color: white;
  
  cursor: pointer;
  padding: 10px;
  transition: 0.3s;
  border: none;
}
.load-more-btn:hover {
  background-color: rgb(0, 0, 0, 0.8);
}
.load-more-btn:active {
  background-color: rgba(61, 61, 61, 0.8);
}

.pc-console-text {
  
  margin-left: 8vh;
  padding-top: 3vh;
  color: black;

  font-weight: bold;
}

/*---------------------------MAIN GAME CARD CSS*/
/*GBT RESPONSEEEE HERE */
/* Container for the cards */
.pc-games {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  gap: 0px; 
  padding: 20px;

}
.game-card {
  background-color: transparent; 
  border-radius: 10px;
  width: 220px; 
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  
  transition: transform 0.3s ease;
  box-sizing: border-box; 
  height: 400px; 
}

.game-card:hover {
  transform: scale(1.05); 
}
.pc-photo-container {
  position: relative;
  width: 200px;
  height: 240px;
}
.img-label {
  position: absolute;
  text-align: center;
  bottom: 10%;
  font-size: 19px;
  padding: 5px 0px 5px 0px;
  left: 0px;
  right: 0px;
  color: white;
  background-color: rgba(93, 91, 91, 0.7);

}
.pc-photo {
  object-fit: cover;
  height: 100%;
  border-radius: 3%;
  max-width: 200px;

}

.game-card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.game-card select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 12px; 
  color: black; 
}

.game-link {
  display: inline-block;
  background-color: rgb(0, 123, 255);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin: 0;
}

.game-link:hover {
  background-color: #0056b3;
}

@media (max-width: 767px) {
  .game-selection-content {
    width: 100%;
  }
  .pc-and-console {
    width: 100%;
    box-shadow: none;
    
  }
  .game-card {
    gap: 0px;
    width: 60%; 
  }
} 







/* GET IN TOUCH*/
.third-main {
 
  display: flex;
  justify-content: center;
  align-items: center;
}
.get-in-touch {
  background-color: rgba(50, 49, 50, 0.7);
 
  display: none;
  flex-wrap:wrap-reverse;
  justify-content: space-around;
  align-items: center;
  
  width: 64%;
  box-sizing: border-box;
  padding: 50px 20px 50px 20px;
  box-shadow: 0 100px 80px rgba(182, 195, 193, 0),
  0px 22.3363px 17.869px rgba(182, 195, 193, 0.0596107),
  0px 6.6501px 5.32008px rgba(182, 195, 193, 0.0403893);
  border-radius: 5px;

  
}

.container-in-touch {
  display: none;
  height: auto;
  width: 200px;

  
}

.get-in-touch-sentence,
.description-in-touch,
.submit-request-main {
  font-weight: lighter;
  margin: 10px;
  
}

.get-in-touch-sentence {
  font-size: 30px;
}


.description-in-touch {
  font-size: 15px;
  margin-bottom: 70px;
  color: rgb(210, 210, 210);
  width: 390px;
}
.get-in-touch-logo-container {
  max-width: 400px;
  min-width: none;
}
.get-in-touch-logo {
  height: 100%;
  width: 100%;
  height: auto;
 
 
}


/* TURNED OFF SUBMIT BUTTON */
.submit-request-main {
  background-color: rgba(46, 46, 46, 1);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  color: rgb(210, 210, 210);
  transition: 0.1s;
  text-decoration: none;
  font-size: 15px;
  display: none;

  
}

.submit-request-main:active {
  background-color: rgb(32, 31, 31);
}


/*FOOTER*/

.footer {
  color:  rgb(72, 72, 72);
  background-color: rgb(25, 25, 25);
  height: 250px;
  
  font-size: 12px;

  display: grid;
  row-gap: 0px;
  grid-column: 1fr;
  text-align: center;
  align-items: center;
}

.footer-img-container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-img {
  height: 100%;
  width: 330px;
}

.footer p {
  margin-top: -20px;
  margin-bottom: -30px;
}

.privacy-rights {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.privacy-rights a {
  color: rgb(72, 72, 72);
  text-decoration: none;
}

.privacy-rights span {
  margin-left: 5px;
}

/*SUBMIT PAGE*/
/* TURNED OFF IF NEEDED YOU CAN TURN ON IT HERE */

.container-submit {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 600px;
  width: 100%;
}

.header-submit {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  letter-spacing: 1px;
}

.header-submit h2 {
  margin: 5px 0 20px 0;
  font-size: 18px;
  color: #aaa;
}

.user-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-options div,
.user-options button {
  background-color: #333;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.user-options .platforms,
.user-options .language {
  flex: 1;
  text-align: center;
}

.main-submit h3 {
  text-align: center;
  margin: 0 0 10px 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 5px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2e2e2e;
  color: #fff;
}

.contact-form input[type="file"] {
  padding: 0;
}

.file-upload {
  margin-bottom: 15px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.create-ticket,
.live-chat {
  background-color: #f0a500;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  flex: 1;
  margin-right: 10px;
}

.live-chat {
  background-color: #333;
  color: #fff;
}

.create-ticket:hover,
.live-chat:hover {
  opacity: 0.9;
}

.theme-switcher {
  width: 30px;
  height: 30px;
  background-color: #666;
  border-radius: 50%;
  cursor: pointer;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: 1px solid #f0a500;
}


/* ADD ONS FOR ADJUSTING BACKGROUND IMAGE -P */

.main-display-content-updated {
  padding: 0;
  margin: 0;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 450px;
}

.main-image-container {
  background-size: contain; 
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; 
  width: 100%;
}

@media (max-width: 768px) {
  .main-image-container {
    width: 70%; 
    height: 100%; 
  }
  .main-display-content-updated {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 1120px) {
  .main-display-content-updated {
    height:350px
  }
}

@media (max-width: 480px) {
  .main-image-container {
    width: 100%; 
    height: 150px;
  }
  .main-display-content-updated {
    width: 100%;
    height: 200px;
  }
}

.welcome {
  background-color: rgba(46, 46, 46, 1);
  padding: 8px;
  border-radius: 15px;
  font-size: 12px;
}

@media (max-width: 768px) {
  .welcome {
    background-color: rgba(46, 46, 46, 1);
    padding: 8px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 700;
  }
}

@media (max-width: 450px) {
  .welcome {
    background-color: rgba(46, 46, 46, 1);
    padding: 6px;
    border-radius: 10px;
    font-size: 10px; 
    font-weight: 700;
  }
  h2{
    padding: 0;
    margin: 0;
  }
}

.help {
  font-weight: normal;
  font-size: 30px;
  margin-top: 1%;
  margin-bottom: 0px;
  color: white;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .help {
    font-weight: 700;
    font-size: 20px;
    margin-top: 1%;
    margin-bottom: 0px;
    color: white;
    border-radius: 15px;
  }
}

@media (max-width: 450px) {
  .help {
    font-weight: 700;
    font-size: 16px; 
    margin-top: 2%; 
    margin-bottom: 0px;
    color: white;
    border-radius: 10px;
  }
}




.main-display-info-container{
  padding-left: 20%;
}

.pc-console-text-updated{
  margin-top: 0;
}

@media (max-width: 768px) {
  
.main-display-info-container{
  padding-left: 10%;
}
.pc-console-text-updated{
  padding-left: 10%;
}
}

@media (max-width: 450px) {

.main-display-info-container{
  padding-left: 5%;
}
.pc-console-text-updated{
  padding-left: 10%;
  margin-bottom: 0;
}
}




